/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import PropTypes from "prop-types"
import ReactMarkdownWithHtml from "react-markdown/with-html"
import gfm from "remark-gfm"
import breaks from "remark-breaks"
import Timer from "../components/timer"
import { FaVolumeUp } from "react-icons/fa"
import Player from "./player"
import styled from "styled-components"
import Tracker from "./tracker"

const StyledCard = styled.div`
  border-radius: 2rem;
  box-shadow: 0 0.25rem 0.5rem 0.1rem #ddd;
  position: relative;
  color: #4a4a4a;
  max-width: 100%;
  background-color: white;
  border: 5px solid #fc7059;
`

const Paragraphs = (props) => {
  return (
    props.paragraphs.map(paragraph => (
      <StyledCard className="card mb-5" key={paragraph.id}>
        <div className="card-content">
          <div className="columns">
            <div className="column is-9">
              <Player source={paragraph.Audio}><FaVolumeUp size="2em" /></Player>
              <ReactMarkdownWithHtml plugins={[gfm, breaks]} allowDangerousHtml>{paragraph.Text}</ReactMarkdownWithHtml>
            </div>
            <div className="column is-3 has-text-centered">
              Goal: {paragraph.Goal}s
              <Timer />
              <Tracker type="check" id={`${props.parentid}-${paragraph.id}`} />
            </div>
          </div>
        </div>
      </StyledCard>
    ))
  )
}

Paragraphs.propTypes = {
  paragraphs: PropTypes.array.isRequired,
}

const Phrases = (props) => {
  return props.phrases.map(phrase => {
    let baseWordsList = []
    Array.apply(0, Array(phrase.Text.split(/\r\n|\r|\n/).length)).forEach(element => {
      baseWordsList.push(<br />)
    })
    phrase.List.map(item => {
      baseWordsList[item.Line - 1] = <Player source={item.Audio}>{item.Word}</Player>
      return true
    })
    return (
      <StyledCard className="card mb-5 is-size-5" key={phrase.id}>
        <div className="card-content">
          <div className="columns">
            <div className="column is-7">
              <Player source={phrase.Audio}><FaVolumeUp size="2em" /></Player>
            </div>
            <div className="column is-5 has-text-centered">
              <h3 className="title is-5">Base Words</h3>
            </div>
          </div>
          <div className="columns">
            <div className="column is-7">
              <ReactMarkdownWithHtml plugins={[gfm, breaks]} allowDangerousHtml>{phrase.Text}</ReactMarkdownWithHtml>
            </div>
            <div className="column is-5 has-text-centered">
              {baseWordsList}
            </div>
          </div>
        </div>
      </StyledCard>
    )
  })
}

Phrases.propTypes = {
  phrases: PropTypes.array.isRequired,
}

export { Paragraphs, Phrases }
