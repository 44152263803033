import React, { useState } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import dash from "../images/dash.svg"
import { ParaPhraseToggle } from "../components/toggle"
import { Paragraphs, Phrases } from "../components/paraphrases"

const HeaderWrapper = styled.div`
  padding-right: 6rem;
  padding-left: 6rem;
  padding-top: 1rem;
  padding-bottom: 4rem;
  @media (max-width: 769px) {
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 4rem;
  }
`

const ToggleWrapper = styled.div`
  @media (max-width: 769px) {
    margin-top: 3rem;
  }
`

const Dash = styled.img`
  width: 5rem;
  display: block;
`

function BookTemplate(props) {
  const [method, setMethod] = useState(false)
  const toggle = () => setMethod(!method)
  return (
    <Layout>
      <SEO title="Book" />

      <HeaderWrapper className="columns">
        <div className="column has-text-centered">
          <h2 className="subtitle is-4 is-pulled-left">
            {props.data.strapiBook.Title}
            <Dash src={dash} alt="some dashes" />
          </h2>
        </div>
        <div className="column">
          {props.data.strapiBook.cover ? (<img src={props.data.strapiBook.cover} alt="" />) : undefined}
        </div>
        <ToggleWrapper className="column">
          <div role="button" tabIndex="0" onClick={toggle} onKeyPress={toggle}>
            <ParaPhraseToggle toggle={method ? undefined : true} />
          </div>
        </ToggleWrapper>
      </HeaderWrapper>

      {method ? (<Phrases phrases={props.data.strapiBook.Phrases} parentid={props.data.strapiBook.id} />) : (<Paragraphs paragraphs={props.data.strapiBook.Paragraphs} />)}

    </Layout>
  )
}

BookTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BookTemplate

export const query = graphql`
  query BookTemplate($id: String!) {
    strapiBook(id: {eq: $id}) {
      Title
      cover
      id
      Paragraphs {
        Audio
        Goal
        Text
        id
      }
      Phrases {
        Audio
        Text
        id
        List {
          Audio
          Word
          Line
          id
        }
      }
    }
  }
`
